<template>
  <Navbar />
  <div class="cancel-session-wrapper">
    <div class="cancel-session-container">
      <div class="cancel-card">
        <h2>Cancelar Sesión</h2>
        <p class="info-text">Estás a punto de cancelar la siguiente sesión:</p>
        <ul class="session-details">
          <li><strong>Fecha:</strong> {{ sessionDateFormatted }}</li>
          <li><strong>Hora:</strong> {{ session.time }}</li>
          <li><strong>Cliente:</strong> {{ session.cliente }}</li>
        </ul>
        <p class="confirm-text">
          ¿Estás seguro que deseas cancelar esta sesión?
        </p>

        <div class="cancel-actions">
          <button @click="confirmCancel" class="btn btn-danger">
            Sí, cancelar sesión
          </button>
          <button @click="goBack" class="btn btn-secondary">
            No, mantener sesión
          </button>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import apiClient from "@/api";
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "CancelSession",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const sessionId = route.query.sessionId;
    const uuid = route.query.uuid;
    const session = ref({
      date: "",
      time: "",
      cliente: "",
    });

    const sessionDateFormatted = computed(() => {
      if (!session.value.date) return "";
      return new Date(session.value.date).toLocaleDateString("es-CL", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    });

    // Llamada al backend para obtener los detalles de la sesión por ID y UUID
    const fetchSessionDetails = async () => {
      try {
        const response = await apiClient.get(`/sesiones/sesion/byId`, {
          params: {
            sessionId,
            uuid,
          },
        });
        session.value = {
          date: response.data.fecha_sesion,
          time: response.data.hora_inicio,
          cliente: response.data.nombre || "Cliente no especificado",
        };
      } catch (error) {
        console.error("Error al obtener los detalles de la sesión:", error);
        alert("Hubo un error obteniendo la información de la sesión.");
      }
    };

    const confirmCancel = async () => {
      try {
        await apiClient.put(`/sesiones/cancelar`, {
          id: sessionId,
          uuid: uuid,
        });
        alert("Sesión cancelada con éxito.");
        router.push("/");
      } catch (error) {
        console.error("Error cancelando la sesión:", error);
        alert("Hubo un error al cancelar la sesión.");
      }
    };

    const goBack = () => {
      router.push("/");
    };

    // Llamada inicial al montarse el componente para obtener los datos de la sesión
    onMounted(() => {
      fetchSessionDetails();
    });

    return {
      session,
      sessionDateFormatted,
      confirmCancel,
      goBack,
    };
  },
};
</script>

<style scoped>
/* Contenedor principal */
.cancel-session-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(
    100vh - 60px
  ); /* Para que tome toda la pantalla menos la altura del footer */
  padding: 20px;
  background-color: #f7f7f7;
}

/* Contenedor de la tarjeta */
.cancel-session-container {
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Logo de la empresa */
.logo {
  width: 150px;
  margin-bottom: 20px;
}

/* Título */
h2 {
  font-size: 1.8rem;
  color: #7f5a35;
  margin-bottom: 10px;
}

/* Texto informativo */
.info-text {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

/* Detalles de la sesión */
.session-details {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.session-details li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

/* Texto de confirmación */
.confirm-text {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 30px;
}

/* Botones de acción */
.cancel-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Botón de cancelar sesión */
.btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
}

.btn-danger {
  background-color: #7f5a35;
  color: white;
  border: none;
}

.btn-danger:hover {
  background-color: #6e4e2f;
}

/* Botón de mantener sesión */
.btn-secondary {
  background-color: #ffd200;
  color: black;
  border: none;
}

.btn-secondary:hover {
  background-color: #e6b800;
}

/* Asegura que el footer esté siempre fijo al fondo de la pantalla */
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 0.9rem;
}

/* Adaptación para pantallas móviles */
@media (max-width: 768px) {
  .cancel-session-container {
    width: 90%;
    padding: 20px;
  }

  .cancel-actions {
    flex-direction: column;
  }
}
</style>
