<template>
  <Navbar />
  <div class="admin-sessions">
    <div class="content">
      <h2 class="title">Gestión de Sesiones</h2>

      <!-- Calendario para ver sesiones agendadas -->
      <div class="calendar-container">
        <FullCalendar :options="calendarOptions" />
      </div>

      <!-- Lista de bloques de tiempo para el día seleccionado -->
      <div v-if="selectedDate" class="session-list">
        <h3>Bloques para {{ selectedDateFormatted }}</h3>

        <!-- Mostrar los bloques de tiempo en columnas -->
        <div class="bloques-disponibles">
          <div class="bloques-grid">
            <div
              v-for="bloque in bloquesDelDia"
              :key="bloque.hora"
              :class="[bloque.status, isSelected(bloque) ? 'seleccionado' : '']"
              class="bloque"
              @click="toggleBloque(bloque)"
            >
              <div class="bloque-hora">
                {{ bloque.hora }} -
                {{
                  bloque.status === "bloqueado"
                    ? "Bloqueado"
                    : bloque.status === "agendada"
                    ? `Agendada por ${bloque.nombre}`
                    : "Disponible"
                }}
              </div>

              <div class="bloque-acciones">
                <button
                  v-if="bloque.status === 'bloqueado'"
                  @click.stop="liberarBloque(bloque.id, bloque.uuid)"
                  class="btn liberar"
                >
                  Liberar
                </button>
                <button
                  v-if="bloque.status === 'agendada'"
                  @click.stop="cancelarSesion(bloque.id, bloque.uuid)"
                  class="btn cancelar"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Botón para bloquear los bloques seleccionados -->
        <button
          v-if="selectedBloques.length > 0"
          @click="bloquearBloques"
          class="btn bloquear-btn"
        >
          Bloquear bloques seleccionados
        </button>

        <!-- Mostrar el botón de bloquear todos SOLO si no hay bloques seleccionados -->
        <button
          v-if="selectedBloques.length === 0 && bloquesDelDia.length > 0"
          @click="bloquearTodos"
          class="btn bloquear-todos-btn"
        >
          Bloquear todos los bloques
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/AdminNavbar.vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import apiClient from "@/api";
import esLocale from "@fullcalendar/core/locales/es";

export default {
  components: {
    Navbar,
    FullCalendar,
  },
  data() {
    return {
      sessions: [],
      selectedDate: null,
      bloquesDelDia: [],
      selectedBloques: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        eventSources: [],
        locales: [esLocale],
        locale: "es",
        dateClick: this.handleDateClick,
        eventContent: this.renderEventContent,
      },
    };
  },
  computed: {
    selectedDateFormatted() {
      if (!this.selectedDate) return "";
      const [year, month, day] = this.selectedDate.split("-");
      const diasSemana = [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ];
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const date = new Date(year, month - 1, day);
      return `${diasSemana[date.getDay()]}, ${day} de ${
        meses[month - 1]
      } de ${year}`;
    },
  },
  methods: {
    async loadSessions() {
      try {
        const response = await apiClient.get("/sesiones/admin/agendadas");

        console.log(response);
        const sessions = response.data.map((session) => ({
          id: session.id,
          title: session.status === "bloqueado" ? "Bloqueado" : "Reservado",
          hora: session.hora_inicio.slice(0, 5),
          status: session.status,
          fecha: session.fecha_sesion.split("T")[0],
          uuid: session.uuid,
          nombre: session.nombre,
        }));

        this.sessions = sessions;
        this.actualizarCalendario();
      } catch (error) {
        console.error("Error al cargar las sesiones:", error);
      }
    },

    actualizarCalendario() {
      const groupedSessions = this.groupSessionsByDate(this.sessions);
      this.calendarOptions.eventSources = [{ events: groupedSessions }];
    },

    groupSessionsByDate(sessions) {
      const grouped = sessions.reduce((acc, session) => {
        const date = session.fecha;
        if (!acc[date]) {
          acc[date] = {
            title: `<div>${
              session.status === "bloqueado" ? "1 bloqueado" : "1 agendada"
            }</div>`,
            start: `${date}T00:00:00`,
            agendadas: session.status === "agendada" ? 1 : 0,
            bloqueadas: session.status === "bloqueado" ? 1 : 0,
          };
        } else {
          if (session.status === "agendada") {
            acc[date].agendadas++;
          } else if (session.status === "bloqueado") {
            acc[date].bloqueadas++;
          }
          acc[
            date
          ].title = `<div>${acc[date].agendadas} agendadas</div><div>${acc[date].bloqueadas} bloqueadas</div>`;
        }
        return acc;
      }, {});

      return Object.values(grouped);
    },

    handleDateClick(info) {
      this.selectedDate = info.dateStr.split("T")[0];
      this.cargarBloquesDelDia();
    },

    cargarBloquesDelDia() {
      const bloquesDisponibles = this.generarBloques();
      const bloquesConStatus = bloquesDisponibles.map((bloque) => {
        const bloqueExistente = this.sessions.find(
          (session) =>
            session.fecha === this.selectedDate && session.hora === bloque
        );

        return {
          id: bloqueExistente ? bloqueExistente.id : null,
          hora: bloque,
          status: bloqueExistente ? bloqueExistente.status : "disponible",
          uuid: bloqueExistente ? bloqueExistente.uuid : null,
          nombre: bloqueExistente ? bloqueExistente.nombre : "",
        };
      });

      this.bloquesDelDia = bloquesConStatus;
    },

    generarBloques() {
      const bloques = [];
      let hora = 9;
      while (hora < 19) {
        bloques.push(`${String(hora).padStart(2, "0")}:00`);
        bloques.push(`${String(hora).padStart(2, "0")}:30`);
        hora++;
      }
      return bloques;
    },

    toggleBloque(bloque) {
      if (bloque.status === "disponible") {
        const bloqueIndex = this.selectedBloques.findIndex(
          (b) => b.hora === bloque.hora
        );
        if (bloqueIndex > -1) {
          this.selectedBloques.splice(bloqueIndex, 1);
        } else {
          this.selectedBloques.push(bloque);
        }
      }
    },

    isSelected(bloque) {
      return this.selectedBloques.some((b) => b.hora === bloque.hora);
    },

    async bloquearBloques() {
      const bloquesParaBloquear = this.selectedBloques.map(
        (bloque) => bloque.hora
      );
      if (bloquesParaBloquear.length > 0) {
        try {
          await apiClient.post("/sesiones/bloquear", {
            fecha: this.selectedDate,
            bloques: bloquesParaBloquear,
          });
          alert("Bloques seleccionados bloqueados con éxito.");
          window.location.reload(); // Recargar la página después de bloquear
        } catch (error) {
          console.error("Error al bloquear bloques:", error);
          alert("Error al bloquear los bloques seleccionados.");
        }
      }
    },

    async bloquearTodos() {
      const bloquesDisponibles = this.bloquesDelDia
        .filter((bloque) => bloque.status === "disponible")
        .map((bloque) => bloque.hora);

      try {
        await apiClient.post("/sesiones/bloquear", {
          fecha: this.selectedDate,
          bloques: bloquesDisponibles,
        });
        alert("Todos los bloques disponibles fueron bloqueados.");
        window.location.reload(); // Recargar la página después de bloquear todos los bloques
      } catch (error) {
        console.error("Error al bloquear todos los bloques:", error);
        alert("Error al bloquear todos los bloques.");
      }
    },

    async liberarBloque(id, uuid) {
      try {
        await apiClient.put(`/sesiones/cancelar/`, {
          id: id,
          uuid: uuid,
        });
        alert("Bloque liberado con éxito.");
        window.location.reload(); // Recargar la página después de liberar
      } catch (error) {
        console.error("Error al liberar el bloque:", error);
        alert("Error al liberar el bloque.");
      }
    },

    async cancelarSesion(id, uuid) {
      console.log("id: ", id);
      console.log("uuid: ", uuid);
      try {
        await apiClient.put(`/sesiones/cancelar/`, {
          id: id,
          uuid: uuid,
        });
        alert("Sesión cancelada con éxito.");
        window.location.reload(); // Recargar la página después de cancelar
      } catch (error) {
        console.error("Error al cancelar la sesión:", error);
        alert("Error al cancelar la sesión.");
      }
    },

    renderEventContent(arg) {
      return { html: `<div class="event-count">${arg.event.title}</div>` };
    },
  },
  async mounted() {
    await this.loadSessions();
  },
};
</script>

<style scoped>
.admin-sessions {
  margin-top: 2em;
  padding: 20px;
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.calendar-container {
  margin: 0 auto;
  max-width: 800px;
}

.bloques-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.bloques-disponibles .bloque {
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bloques-disponibles .bloque.disponible {
  background-color: #e0ffe0;
}

.bloques-disponibles .bloque.bloqueado {
  background-color: #ffcccc;
}

.bloques-disponibles .bloque.agendada {
  background-color: #ffffcc;
}

.bloques-disponibles .bloque.seleccionado {
  border: 2px solid #007bff;
  background-color: #cce5ff;
}

.bloque-hora {
  font-weight: bold;
}

.bloque-acciones {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.liberar,
.cancelar {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.liberar:hover {
  background-color: #c82333;
}

.cancelar {
  background-color: #ffc107;
  color: black;
}

.cancelar:hover {
  background-color: #e0a800;
}

.bloquear-btn,
.bloquear-todos-btn {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
}

.bloquear-btn:hover,
.bloquear-todos-btn:hover {
  background-color: #0056b3;
}

.event-count {
  color: #007bff;
  font-size: 0.9em;
  text-align: center;
  margin-top: 5px;
}
</style>
