<template>
  <div>
    <Navbar @open-carrito="toggleCarrito" />
    <Carrito v-if="mostrarCarrito" @close="toggleCarrito" />
    <div>
      <main class="main-content">
        <section class="banner">
          <div class="banner-content">
            <h1>Inspirado en Bali</h1>
            <h2>Hecho en Chile</h2>
          </div>
        </section>
        <section class="lineaCafe">
          <div class="marquee">
            <div class="marquee-content">
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
              <span>Proxima apertura - Los Angeles - Pueblito Siglo XXI - Bali
                Solarium</span>
            </div>
          </div>
        </section>
        <section class="sesiones-section">
          <Sesiones @agregar-al-carrito="agregarAlCarrito" />
        </section>
        <section class="productos-section">
          <Productos @agregar-al-carrito="agregarAlCarrito" />
        </section>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Productos from "../components/ProductosPage.vue";
import Sesiones from "../components/Sesiones.vue";
import Navbar from "../components/NavbarTop.vue";
import Carrito from "../components/Carrito.vue";
import Footer from "../components/Footer.vue";
import state from "../state";

export default {
  components: {
    Productos,
    Sesiones,
    Navbar,
    Carrito,
    Footer,
  },
  data() {
    return {
      mostrarCarrito: false,
    };
  },
  methods: {
    toggleCarrito() {
      this.mostrarCarrito = !this.mostrarCarrito;
    },
    agregarAlCarrito(producto) {
      state.agregarAlCarrito(producto);
    },
  },
};
</script>

<style scoped>
.main-content {
  margin-top: 3.5em;
  padding-top: 1em;
  text-align: center;
  flex: 1;
  width: 100%;
}

.banner {
  background-image: url("../assets/banner.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  width: 100%;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.banner h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.banner h2 {
  font-size: 1.8rem;
  margin: 0;
}

.lineaCafe {
  height: 3em;
  background-color: #7f5a35;
  color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.marquee {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: marquee 60s linear infinite;
  width: 100%;
}

.marquee-content span {
  display: inline-block;
  padding-right: 2rem;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.productos-section {
  margin-top: 2rem;
  width: 100%;
}

.sesiones-section {
  width: 100%;
  margin-top: 2rem;
}
</style>
