<template>
  <Navbar />
  <div class="admin-dashboard">
    <div class="content">
      <div class="sales-header">
        <h2 class="title">Últimas Ventas</h2>
        <div class="search-bar">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Buscar por cliente o ID"
            @input="filterVentas"
          />
        </div>
      </div>
      <div v-if="loading" class="loading">Cargando ventas...</div>
      <div v-else class="ventas-list">
        <table class="ventas-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Cliente</th>
              <th>Monto</th>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="venta in filteredVentas" :key="venta.id">
              <td>{{ venta.id }}</td>
              <td>{{ venta.cliente }}</td>
              <td>{{ formatPrice(venta.total) }}</td>
              <td>{{ formatDate(venta.fecha_venta) }}</td>
              <td>{{ formatStatus(venta.status) }}</td>
              <td>
                <button @click="viewDetails(venta.id)" class="btn">
                  Ver Detalles
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="selectedVenta" class="modal">
        <div class="modal-content">
          <h3>Detalles de la Venta</h3>
          <button @click="closeDetails" class="btn cancel">Cerrar</button>
          <div v-if="ventaDetalles">
            <p><strong>Cliente:</strong> {{ ventaDetalles.venta.cliente }}</p>
            <p>
              <strong>Monto:</strong>
              {{ formatPrice(ventaDetalles.venta.total) }}
            </p>
            <p>
              <strong>Fecha:</strong>
              {{ formatDate(ventaDetalles.venta.fecha_venta) }}
            </p>
            <p>
              <strong>Estado:</strong>
              {{ formatStatus(ventaDetalles.venta.status) }}
            </p>
            <h4>Productos:</h4>
            <ul>
              <li v-for="producto in ventaDetalles.detalles" :key="producto.id">
                {{ producto.nombre }} - {{ producto.cantidad }} x
                {{ formatPrice(producto.precio_unitario) }}
              </li>
            </ul>
            <h4>Transacciones:</h4>
            <ul>
              <li
                v-for="transaccion in ventaDetalles.transacciones"
                :key="transaccion.id"
              >
                Referencia: {{ transaccion.reference }} - Monto:
                {{ formatPrice(transaccion.monto) }} - Fecha:
                {{ formatDate(transaccion.fecha_transaccion) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api";
import Navbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Navbar,
  },

  data() {
    return {
      ventas: [],
      filteredVentas: [],
      searchQuery: "",
      loading: true,
      error: null,
      selectedVenta: null,
      ventaDetalles: null,
    };
  },
  async created() {
    await this.loadVentas();
  },
  methods: {
    async loadVentas() {
      try {
        const response = await apiClient.get("/ventas");
        this.ventas = response.data;
        this.filteredVentas = this.ventas; // Inicializa la lista filtrada
      } catch (error) {
        this.error = "Error al cargar las ventas.";
      } finally {
        this.loading = false;
      }
    },
    filterVentas() {
      const query = this.searchQuery.toLowerCase();
      this.filteredVentas = this.ventas.filter(
        (venta) =>
          venta.cliente.toLowerCase().includes(query) ||
          venta.id.toString().includes(query)
      );
    },
    async viewDetails(id) {
      try {
        const response = await apiClient.get(`/ventas/${id}`);
        this.ventaDetalles = response.data;
        this.selectedVenta = id;
      } catch (error) {
        console.error("Error al cargar los detalles de la venta:", error);
        alert("Error al cargar los detalles de la venta.");
      }
    },
    closeDetails() {
      this.selectedVenta = null;
      this.ventaDetalles = null;
    },
    formatPrice(value) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleDateString("es-CL");
    },
    formatStatus(status) {
      const statuses = {
        1: "Completado",
        2: "Pendiente",
        3: "Rechazado",
        // Agrega más estados si es necesario
      };
      return statuses[status] || "Desconocido";
    },
  },
};
</script>

<style scoped>
.admin-dashboard {
  padding-top: 80px; /* Para compensar la altura del navbar */
  margin-left: 2em;
  margin-right: 2em;
}

.title {
  text-align: center;
}

.search-bar {
  margin-bottom: 20px;
  text-align: center;
}

.search-bar input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.ventas-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ventas-table {
  width: 100%;
  border-collapse: collapse;
}

.ventas-table th,
.ventas-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.ventas-table th {
  background-color: #f4f4f4;
}

.btn {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.btn.cancel {
  background-color: #6c757d;
}

.btn:hover {
  opacity: 0.9;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
}

.product-form .form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.btn.cancel {
  background-color: #6c757d;
}
</style>
